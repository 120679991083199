var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex df-row full-container"
  }, [_c('div', {
    staticClass: "d-flex df-col terms-container"
  }, [_c('div', {
    staticClass: "terms-inner d-flex df-col"
  }, [_c('div', {
    staticClass: "terms-body d-flex df-col"
  }, [_c('h3', {
    staticClass: "terms-title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "divider"
  }), _c('perfect-scrollbar', [_c('div', {
    staticClass: "terms-contents",
    domProps: {
      "innerHTML": _vm._s(_vm.contents)
    }
  }), _c('div', {
    staticClass: "terms-change"
  }, [_c('span', {
    staticClass: "terms-change-title"
  }, [_vm._v(" " + _vm._s(_vm.currentTitle) + " 변경 사항 보기 ")]), _c('div', {
    ref: "terms-selector",
    staticClass: "terms-selector d-flex df-row cursor",
    on: {
      "click": _vm.termsFloatingInfo
    }
  }, [_c('p', {
    staticClass: "selected-terms"
  }, [_vm._v(_vm._s(_vm.current))]), _c('img', {
    staticClass: "terms-selector-icon",
    attrs: {
      "src": require('@/assets/images/introduction/btn_down.svg')
    }
  })])])])], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }