<template>
  <div class="d-flex df-row full-container">
    <div class="d-flex df-col terms-container">
      <div class="terms-inner d-flex df-col">
        <div class="terms-body d-flex df-col">
          <h3 class="terms-title">{{ title }}</h3>
          <div class="divider" />
          <perfect-scrollbar>
            <div v-html="contents" class="terms-contents"></div>
            <div class="terms-change">
              <span class="terms-change-title">
                {{ currentTitle }} 변경 사항 보기
              </span>
              <div
                class="terms-selector d-flex df-row cursor"
                ref="terms-selector"
                @click="termsFloatingInfo"
              >
                <p class="selected-terms">{{ current }}</p>
                <img
                  :src="require('@/assets/images/introduction/btn_down.svg')"
                  class="terms-selector-icon"
                />
              </div>
            </div>
          </perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {PerfectScrollbar} from "vue2-perfect-scrollbar";
import termsAPI from "@/api/accounts/terms";
import {createNamespacedHelpers} from "vuex";
const ModuleAlert = createNamespacedHelpers("ModuleAlert");
const ModuleIntroCommon = createNamespacedHelpers("ModuleIntroCommon");

export default {
  data() {
    return {
      title: "",
      contents: "",
      termsId: 0,
      terms: [],
      current: ""
    };
  },
  computed: {
    changeTermsId() {
      return this.termsId;
    },
    currentTitle() {
      return this.$route.query.type === "use" ? "이용약관" : "개인정보처리방침";
    },
    ...ModuleIntroCommon.mapGetters(["getTemp"])
  },
  watch: {
    $route() {
      this.searchAllTerms();
    },
    getTemp(newVal) {
      let type =
        this.$route.query.type === "use" ? "이용약관" : "개인정보처리방침";
      this.termsId = newVal.id;
      this.current =
        "시행일자: " + this.timestampFilter(newVal.startedAt, "ko") + type;
    },
    changeTermsId() {
      scrollTo({top: 0, behavior: "smooth"});

      this.searchTerms();
    }
  },
  created() {
    this.setCurrentMenuInfo({
      id: 999,
      name: "약관",
      path: "/introduction/terms?" + this.$route.query.type
    });
    this.searchAllTerms();
  },
  methods: {
    //모든 약관목록 조회
    searchAllTerms() {
      let payload = {};

      payload.type =
        this.$route.query.type === "use" ? "termsOfUse" : "privacyPolicy";
      payload.sortBy = "id-";

      termsAPI.allTerms(payload).then(res => {
        //현재 선택된 약관 셋팅
        let latest = res.data.list[0];
        let type =
          this.$route.query.type === "use" ? "이용약관" : "개인정보처리방침";

        this.termsId = latest.id;
        this.current =
          "시행일자: " + this.timestampFilter(latest.startedAt, "ko") + type;

        //약관목록 리스트 셋팅
        this.terms = [];
        const list = res.data.list;

        list.forEach(item => {
          item["name"] =
            "시행일자: " + this.timestampFilter(item.startedAt, "ko") + type;
          item["clickType"] = "temp";

          this.terms.push(item);
        });
      });
    },
    //약관조회 및 셋팅
    searchTerms() {
      if (this.$route.query.type === "use") {
        let payload = {};
        payload.termsId = this.termsId;

        termsAPI.currentTermsOfUse(payload).then(res => {
          this.title = "이용약관";
          this.contents = res.data.contents["ko"];
        });
      } else if (this.$route.query.type === "privacy") {
        let payload = {};
        payload.termsId = this.termsId;

        termsAPI.currentPrivacyPolicy(payload).then(res => {
          this.title = "개인정보처리방침";
          this.contents = res.data.contents["ko"];
        });
      } else {
        this.setAlert({
          isVisible: true,
          message: `잘못된 접근입니다.`,
          event: this.$router.go(-1) || window.close()
        });
      }
    },
    //약관 변경 플로팅 팝업
    termsFloatingInfo() {
      const target = this.$refs["terms-selector"];
      const {bottom, left, width} = target.getBoundingClientRect();

      let x = bottom;
      let y = left;

      this.setFloatingPosition({
        top: x + "px",
        left: y + "px",
        width: width + "px"
      });
      this.setFloatingData(this.terms);
      this.setFloatingType("terms-select");
      this.setFloatingShowState(true);
    },
    // 날짜형식 필터링
    timestampFilter(value, type) {
      if (value === "") return "";

      let date = new Date(value);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();

      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;
      if (hours < 10) hours = "0" + hours;
      if (minutes < 10) minutes = "0" + minutes;
      if (seconds < 10) seconds = "0" + seconds;

      let result =
        type === "timestamp"
          ? year +
            "-" +
            month +
            "-" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds
          : type === "ko"
          ? year + "년 " + month + "월 " + day + "일 "
          : year + "-" + month + "-" + day;

      return result;
    },
    ...ModuleAlert.mapMutations(["setAlert"]),
    ...ModuleIntroCommon.mapMutations([
      "setCurrentMenuInfo",
      "setFloatingShowState",
      "setFloatingData",
      "setFloatingType",
      "setFloatingPosition",
      "setTemp"
    ])
  },
  components: {
    PerfectScrollbar
  }
};
</script>

<style lang="scss">
.full-container {
  background: #f7f9fc;
}
.terms-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #2c2c2c;
}
.divider {
  width: 100%;
  background: #f1f1f1;
  margin: 16px 0;
}
.terms-contents {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #484948;
}
.terms-container {
  width: 100%;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  @media screen and (min-width: 720px) {
    position: relative;
    width: 56%;
    height: 85%;
    background: #fff;
    border-radius: 8px;
    margin: 24px auto 30px auto;
    box-sizing: border-box;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
  }
}
.terms-inner {
  padding: 24px 12px 12px;
  @media screen and (min-width: 720px) {
    height: 100%;
    padding: 42px 48px 80px;
  }
}
.terms-body {
  padding: 0 0 16px 0;
  @media screen and (min-width: 720px) {
    position: relative;
    height: 100%;
    padding: 28px 0 16px 0;
    box-sizing: border-box;
  }
}
.terms-contents-wrapper {
  height: 100%;
  @media screen and (min-width: 720px) {
    position: absolute;
    top: 80px;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
.terms-contents {
  height: fit-content;
}
.terms-change {
  margin-top: 48px;
  width: fit-content;
}
.terms-change-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: -0.005em;
  color: #484948;
}
.terms-selector {
  min-width: 242px;
  height: 32px;
  margin: 16px 0 0 0;
  border: 1px solid #8d8d8d;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 16px;
}
.selected-terms {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  margin: auto;
  color: #8d8d8d;
}
.terms-selector-icon {
  width: 13px;
  margin: auto auto auto 10px;
}
</style>
